import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{ref:"dialog",attrs:{"max-width":"800px"},model:{value:(_vm.showReplaceIngredients),callback:function ($$v) {_vm.showReplaceIngredients=$$v},expression:"showReplaceIngredients"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Replace Ingredient")]),_c(VCardText,[_vm._v(" Choose an ingredient you would like to use as a replacement for "),_vm._v(" "+_vm._s(_vm.replaceSelected.map(function (s) { return (((s.fooddescription) + " [" + (s.foodid) + "]")); }).join(', '))+" "),_c('IngredientAutocomplete',{on:{"updateValue":function($event){_vm.replacement.ingredient=$event}}}),_c('p',{staticClass:"caption"},[_vm._v("Note: a meal/component cannot have duplicate ingredients, so you cannot replace something with an ingredient that already exists in the meal. You are allowed have the same ingredient as long as they are in different components of the same meal.")]),_vm._v(" The following meals will be have this ingredient replaced "),_c('ul',_vm._l((_vm.replaceSelected),function(item){return _c('li',{key:item.foodid},[_vm._v(" "+_vm._s(item.name)+" found in "+_vm._s(item.meal_food.length)+" meals "),_c('ul',_vm._l((item.meal_food),function(ref){
var meal = ref.meal;
return _c('li',{key:meal.id},[_vm._v(" "+_vm._s(meal.name)+" ("+_vm._s(meal.id)+" ) ")])}),0)])}),0)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{on:{"click":_vm.cancelReplaceIngredients}},[_vm._v("Cancel")]),_c(VBtn,{on:{"click":_vm.closeReplaceIngredients}},[_vm._v("Apply")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }