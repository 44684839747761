<template>
  <v-dialog
      v-model="showReplaceIngredients"
      ref="dialog"
      max-width="800px"
  >
    <v-card>
      <v-card-title>Replace Ingredient</v-card-title>
      <v-card-text>
        Choose an ingredient you would like to use as a replacement for
<!--        {{ replaceSelected }}-->
        {{ replaceSelected.map(s => (`${s.fooddescription} [${s.foodid}]`)).join(', ') }}
        <IngredientAutocomplete
            @updateValue="replacement.ingredient=$event"
        />
        <p class="caption">Note: a meal/component cannot have duplicate ingredients, so you cannot replace something
          with an ingredient that already exists in the meal.
          You are allowed have the same ingredient as long as they are in different components of the same meal.</p>
        The following meals will be have this ingredient replaced
        <ul>
          <li v-for="item of replaceSelected" v-bind:key="item.foodid">
            {{ item.name }} found in {{item.meal_food.length}} meals
            <ul>
              <li v-for="{meal} of item.meal_food"
                  v-bind:key="meal.id">
                {{ meal.name }} ({{ meal.id }} )
<!--                {{ ingredientToMealMap[item.foodid][mealId] }}CT-->
              </li>
            </ul>
          </li>
        </ul>

      </v-card-text>

      <v-card-actions>

        <v-spacer/>
        <v-btn @click="cancelReplaceIngredients">Cancel</v-btn>
        <v-btn @click="closeReplaceIngredients">Apply</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import IngredientAutocomplete from '@/components/IngredientAutocomplete'

export default {
  name: 'ReplaceIngredientDialog',
  components: {IngredientAutocomplete},
  props: {
    cancelReplaceIngredients: {},
    closeReplaceIngredients: {},
    meals : {},
    replaceSelected: {},
    replacement: {},
    showReplaceIngredients: {}
  }
}
</script>
