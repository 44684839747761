import { render, staticRenderFns } from "./IngredientAutocomplete.vue?vue&type=template&id=36e672e5&scoped=true&"
import script from "./IngredientAutocomplete.vue?vue&type=script&lang=js&"
export * from "./IngredientAutocomplete.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36e672e5",
  null
  
)

export default component.exports