<template>
  <!--  hide-no-data-->
  <!--  hide-selected-->
  <!--  return-object-->
  <!--  autofocus-->


  <v-autocomplete
      :clearable="clearable"
      :dense="dense"
      v-click-outside="clickOutside"
      @blur="close"
      @change="close"
      v-model="editValue"
      :items="items"
      :loading="isLoading"
      :search-input.sync="search"
      color="black"
      auto-select-first
      item-text="name"
      item-value="foodid"
      label=""
      placeholder="Enter an ingredient to search"
      :suffix="editValue && `${editValue.foodid?editValue.foodid:''}`"
      :hint="editValue && editValue.name && `${editValue.name}, ${editValue.foodid}`"
      return-object
      :hide-no-data="true"
  >
    <template slot="item" slot-scope="data">
      <span>
        {{ data.item.name }} {{ (data.item.id) }} <v-icon color="blue"
                                                          v-if="data.item.verified">mdi-shield-check</v-icon>
        <br/>
        <span class="caption"> {{ data.item.fooddescription }}</span>
      </span>
    </template>
  </v-autocomplete>
</template>

<script>
import api from '@/api';

export default {
  name: 'IngredientAutocomplete',
  props: {
    ingredient: Object,
    dense: {type: Boolean, default: false, required: false},
    clickOutsideClose: {type: Boolean, default: true, required: false},
    clearable: {type: Boolean, default: false, required: false}
  },
  mounted() {
    this.setSearch(this.ingredient);
  },
  data() {
    return {
      editValue: null,
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      model: null,
      search: null,
      lastSearch: null
    };
  },
  watch: {
    search(query) {
      if (query === null) {
        return;
      }
      // console.log('query', query);
      // Items have already been loaded
      // if (this.items.length > 0) {
      //   console.log('Items have already been loaded');
      //   return;
      // }

      // Items have already been requested
      if (this.isLoading) {
        // console.log('Items have already been requested');
        return;
      }

      // Lazily load input items
      // console.log(`GET /foodname/${query}?limit=100&minimal=true`);
      const searchUrl = `/foodname/${encodeURIComponent(query)}?limit=100&minimal=true`;
      if (this.lastSearch !== searchUrl) {
        this.lastSearch = searchUrl;
        this.isLoading = true
        api.get(searchUrl)
            .then(res => res.data)
            .then(entries => {
              // sort so verified come before unverified
              //entries.sort((a, b) => (b.verified ? 1 : 0) - (a.verified ? 1 : 0));
              this.count = entries.length
              this.entries = entries;
              // console.log('entries', entries);
            })
            .catch(err => {
              console.log('search failed', err);
            })
            .finally(() => (this.isLoading = false))
      }
    },
    ingredient: 'setSearch'
  },

  computed: {
    items: function () {
      if (!this.entries) {
        return [this.editValue];
      } else {
        const mappedList = this.entries
            .map(this.formatName);
        // mappedList.sort((a, b) => a.name.localeCompare(b.name));
        // console.log('items', mappedList);
        return mappedList;
      }
    },
  },
  methods: {
    formatName(entry) {
      const name = (entry.fooddescriptionsimple || entry.fooddescription || '').trim();
      const newName = name.length > this.descriptionLimit
          ? name.slice(0, this.descriptionLimit) + '...'
          : name
      return Object.assign({},
          entry,
          {
            name: newName ? `${newName} ${entry.foodid}` : '',
            displayName: newName
          });
    },
    setSearch(i) {
      if (i) {
        // console.log('setting', i);
        this.editValue = this.formatName(i);
        // console.log('IngredientAutocomplete mounted', this.editValue);
        this.entries = [this.editValue];
        this.search = this.editValue.fooddescriptionsimple || '';
        // console.log('set search', {search: this.search});
      } else {
        this.editValue = {};
      }
    },
    close() {
      // console.log('closing', this.editValue);
      // this.$emit('update:ingredient', this.editValue);
      this.$emit('close', this.editValue);
      this.$emit('updateValue', this.editValue);
      this.$emit('update:ingredient', this.editValue);
    },
    clickOutside() {
      if (this.clickOutsideClose) {
        this.close();
      }
    }
  }
}
</script>

<style scoped>

</style>
